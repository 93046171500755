<template>
  <validation-observer ref="simpleRules">
    <b-form v-if="!editRow" @submit.prevent>
      <b-row>
        <b-col v-for="item in items" :key="item.id" cols="12">
          <b-form-group :label="item.arabicName" :label-for="'v-' + item.name">
            <b-form-input :id="'v-' + item.name" :type="item.type" v-model="data[item.name]" :placeholder="item.arabicName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="رقم البوليصة الكلية" label-for="name-input">
            <b-form-input id="totalBillOfLandingNumber-input" v-model="data['TotalBillOfLandingNumber']"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="رقم البوليصة الفرعية" label-for="name-input">
            <b-form-input id="subBillOfLandingNumber-input" v-model="data['SubBillOfLandingNumber']"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="تاريخ استلام البوليصة" label-for="name-input">
            <b-form-datepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="ar"
              size="lg"
              v-model="data['ReceiptDate']"
              placeholder=""
            ></b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="اسم المندوب" :label-for="'v-agentName'">
            <validation-provider #default="{ errors }" name="اسم المندوب" rules="required">
              <b-dropdown
                style="width: 100%"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="agentName == null ? 'اسم المندوب' : agentName"
                v-model="data['AgentName']"
                :state="errors.length > 0 ? false : null"
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="agent in agents"
                  :key="agent.id"
                  :value="agent.userDisplayName"
                  @click=";(agentName = agent.userDisplayName), (data['AgentName'] = agent.userName)"
                >
                  {{ agent.userDisplayName }}
                </b-dropdown-item>
              </b-dropdown>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="اسم الشركة" :label-for="'v-companyName'">
            <!-- <validation-provider
                  #default="{ errors }"
                  name="اسم الشركة"
                  rules="required"
                > -->
            <b-dropdown
              style="width: 100%"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="companyName == null ? 'اسم الشركة' : companyName"
              v-model="data['companyName']"
              variant="outline-primary"
            >
              <!-- :state="errors.length > 0 ? false:null" -->
              <b-dropdown-item
                v-for="company in companies"
                :key="company.id"
                :value="company.userDisplayName"
                @click=";(companyName = company.userDisplayName), (data['CompanyName'] = company.userName)"
              >
                {{ company.userDisplayName }}
              </b-dropdown-item>
            </b-dropdown>
            <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1" @click="validationForm"> إضافة </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="back" variant="primary" @click="this.back"> رجوع </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-form v-else @submit.prevent>
      <b-row>
        <b-col v-for="item in items" :key="item.id" cols="12">
          <b-form-group :label="item.arabicName" :label-for="'v-' + item.name">
            <b-form-input :id="'v-' + item.name" :type="item.type" v-model="row[item.name]" :placeholder="item.arabicName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="رقم البوليصة الكلية" label-for="name-input">
            <b-form-input id="totalBillOfLandingNumber-input" v-model="row['TotalBillOfLandingNumber']"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="رقم البوليصة الفرعية" label-for="name-input">
            <b-form-input id="subBillOfLandingNumber-input" v-model="row['SubBillOfLandingNumber']"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="تاريخ استلام البوليصة" label-for="name-input">
            <b-form-datepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="ar"
              size="lg"
              v-model="row['ReceiptDate']"
              placeholder=""
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="اسم المندوب" :label-for="'v-agentName'">
            <b-dropdown
              style="width: 100%"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="row['AgentName']"
              v-model="row['AgentName']"
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="agent in agents"
                :key="agent.id"
                :value="agent.userDisplayName"
                @click=";(row['AgentName'] = agent.userName), (agentName = row['AgentName'])"
              >
                {{ agent.userDisplayName }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="كومنت العميل" label-for="v-agentComment">
            <b-form-input id="v-agentComment" type="text" v-model="row['AgentComment']" placeholder="كومنت العميل" />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="اسم الشركة" :label-for="'v-companyName'">
            <b-dropdown
              style="width: 100%"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="row['CompanyName']"
              v-model="row['CompanyName']"
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="company in companies"
                :key="company.id"
                :value="company.userDisplayName"
                @click=";(row['CompanyName'] = company.userName), (companyName = row['CompanyName'])"
              >
                {{ company.userDisplayName }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label="كومنت الشركة"
            label-for="v-companyCommnet"
          >
            <b-form-input
              id="v-companyCommnet"
              type="text"
              v-model="row['CompanyComment']"
              placeholder="كومنت الشركة"
            />
          </b-form-group>
        </b-col> -->

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1" @click="validationForm"> تعديل </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="back" variant="primary" @click="this.back"> رجوع </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BDropdown, BDropdownItem, BForm, BButton, BFormDatepicker } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BForm,
    BButton,
    BFormDatepicker
  },
  directives: {
    Ripple
  },
  props: ['id'],
  created() {
    this.GetReportItems()
    this.GetAgents()
    this.GetCompanies()
    if (this.$route.params.id) {
    }
  },

  data() {
    return {
      // for validation
      required,
      items: [],
      data: {},
      editRow: false,
      row: null,
      agents: [],
      companies: [],
      agentName: null,
      companyName: null,
      totalBillOfLandingNumber: '',
      subBillOfLandingNumber: '',
      receiptDate: null
    }
  },
  methods: {
    async GetReportItems() {
      try {
        var response = await this.$store.dispatch('GetReportItems')
        if ((response.status = 200)) {
          this.items = response.data
          if (this.$route.params.id) {
            this.GetRow()
            this.editRow = true
          } else {
            for (var i = 0; i < this.items.length; i++) {
              this.data[this.items[i].name] = ''
            }

            this.data['AgentName'] = ''
            this.data['CompanyName'] = ''
            this.data['SubBillOfLandingNumber'] = ''
            this.data['TotalBillOfLandingNumber'] = ''
            this.data['ReceiptDate'] = ''
          }
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async GetAgents() {
      try {
        var response = await this.$store.dispatch('GetAgents')
        if ((response.status = 200)) {
          this.agents = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async GetCompanies() {
      try {
        var response = await this.$store.dispatch('GetCompanies')
        if ((response.status = 200)) {
          this.companies = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async GetRow() {
      try {
        var response = await this.$store.dispatch('GetRow', this.$route.params.id)
        if ((response.status = 200)) {
          this.row = response.data
          this.row['ReceiptDate'] = new Date(this.row['ReceiptDate'])
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && !this.editRow) {
          // eslint-disable-next-line
          this.AddRow()
        } else if (success && this.editRow) {
          this.EditRow()
        }
      })
    },
    async AddRow() {
      this.data['ReleaseStatus'] = false
      try {
        const response = await this.$store.dispatch('AddRow', this.data)

        if ((response.status = 200)) {
          this.$router.push('full-report')
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async EditRow() {
      try {
        const response = await this.$store.dispatch('UpdateRow', this.row)

        if ((response.status = 200)) {
          this.$router.push({ name: 'full-report' })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    back() {
      this.$router.push({ name: 'full-report' })
    }
  }
}
</script>

<style>
.dropdown-menu.show {
  max-height: 250px;
  overflow-y: scroll;
}
</style>
